import React, { useEffect } from "react";
import { ScreenSizes, Sx } from "../../types/responsive";
import { KeenSliderOptions, useKeenSlider } from "keen-slider/react";
import styled from "styled-components";
import { StyledDiv } from "./StyledSx";
import "keen-slider/keen-slider.min.css";

const Wrapper = styled(StyledDiv)`
  overflow: hidden !important;
  gap: 0 !important;
`;

const Slide = styled(StyledDiv)`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    max-height: 100%;
    margin: 0;
  }
`;

type MediaOption = {all: number; lg?: number; md?: number; sm?: number; xs?: number};

type SliderOptions = {
  perView: MediaOption;
  spacing?: MediaOption;
  loop?: boolean;
  autoplay?: boolean;
  origin?: number | 'center' | 'auto';
};

type Props = {
  items: any[];
  options: SliderOptions;
  sx?: Sx;
  slideHeight: number;
};

const animation = {
  duration: 400,
  easing: (x: number) => {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }
};

const sliderConfig = (length: number, options: SliderOptions): KeenSliderOptions => ({
  loop: options.loop || false,
  mode: 'snap',
  renderMode: 'performance',
  slides: {
    origin: options.origin || 'center',
    number: length,
    perView: options.perView.all,
    spacing: options.spacing?.all || 0
  },
  breakpoints: {
    ['(max-width: ' + ScreenSizes.lg + ')']: {
      slides: {
        ...(options.perView.lg ? {perView: options.perView.lg} : {}),
        ...(options.spacing?.lg ? {spacing: options.spacing.lg} : {})
      }
    },
    ['(max-width: ' + ScreenSizes.md + ')']: {
      slides: {
        ...(options.perView.md ? {perView: options.perView.md} : {}),
        ...(options.spacing?.md ? {spacing: options.spacing.md} : {})
      }
    },
    ['(max-width: ' + ScreenSizes.sm + ')']: {
      slides: {
        ...(options.perView.sm ? {perView: options.perView.sm} : {}),
        ...(options.spacing?.sm ? {spacing: options.spacing.sm} : {})
      }
    },
    ['(max-width: ' + ScreenSizes.xs + ')']: {
      slides: {
        ...(options.perView.xs ? {perView: options.perView.xs} : {}),
        ...(options.spacing?.xs ? {spacing: options.spacing.xs} : {})
      }
    },

  },
  created(s) {
    options.autoplay && s.moveToIdx(2, true, animation);
  },
  animationEnded(s) {
    options.autoplay &&
      setTimeout(() => {
        s.moveToIdx(s.track.details.abs + 1, true, animation);
      }, 2500);
  }
});

export const Slider: React.FC<Props> = ({items, options, sx, slideHeight}) => {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    sliderConfig(items.length, options)
  );

  useEffect(() => {
    if (!sliderRef.current) return;
    sliderRef.current.update();
  }, [instanceRef]);

  return (
    <Wrapper ref={sliderRef} className="keen-slider" sx={sx}>
      {items.map((item) => (
        <Slide className="keen-slider__slide" sx={{height: slideHeight + 'px'}}>
          {item}
        </Slide>
      ))}
    </Wrapper>
  );
};
