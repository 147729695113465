// i18next-extract-mark-ns-start mb-way
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import modular from 'images/modular.svg';
import mbWay_monei from 'images/mb-way_monei.svg';
import trendgraph from 'images/trendgraph.svg';
import person from 'images/person.svg';
import heartbubble from 'images/heartbubble.svg';
import shield from 'images/shield.svg';
import mobile_payment from 'images/mobile_payment.svg';
import building2 from 'images/building2.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {Card, CardImage, CardWrapper} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {HomePartners} from 'components/HomePartners';
import {PaymentMethods} from 'components/PaymentMethods';
import Bold from 'components/Bold';
import {FaqsSection} from '../../components/FaqsSection';
import {SupportLink} from '../../components/links/Support';
import {BlogLink} from '../../components/links/Blog';
import {AnchorLink} from '../../components/AnchorLink';
import {PrismicPage} from '../../components/links/PrismicPage';
import {InternalPageLink} from '../../components/links/Pages';
import {AndroidDownloadLink} from '../../components/DownloadOnGooglePlay';
import {IosDownloadLink} from '../../components/DownloadOnAppleStore';
import {JSX} from 'preact';
import {StyledImg} from '../../components/StyledSx';
import visa_logo from '@wp-pay/logos/dist/methods/visa/method-visa-640x360.svg';
import paypal_logo from '@wp-pay/logos/dist/methods/paypal/method-paypal-640x360.svg';
import mastercard_logo from '@wp-pay/logos/dist/methods/mastercard/method-mastercard-640x360.svg';
import googlepay_logo from '@wp-pay/logos/dist/methods/google-pay/method-google-pay-640x360.svg';
import applepay_logo from '@wp-pay/logos/dist/methods/apple-pay/method-apple-pay-640x360.svg';
import bizum_logo from '../../images/bizum_payment_gateway.svg';
import sepa_logo from 'images/sepa-logo.svg';
import jcb_logo from '@wp-pay/logos/dist/cards/jcb/card-jcb-logo-_x80.svg';
import dinners_logo from '@wp-pay/logos/dist/cards/diners-club/card-diners-club-logo-_x80.svg';
import discover_logo from '@wp-pay/logos/dist/cards/discover/card-discover-logo-_x80.svg';
import {Slider} from '../../components/Slider';
import {ButtonLink} from '../../components/Button';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const paymentMethods: JSX.Element[] = [
  <StyledImg src={visa_logo} alt="Visa" title="Visa" />,
  <StyledImg src={paypal_logo} alt="PayPal" title="PayPal" />,
  <StyledImg src={mastercard_logo} alt="Mastercard" title="Mastercard" />,
  <StyledImg src={googlepay_logo} alt="Google Pay" title="Google Pay" />,
  <StyledImg src={applepay_logo} alt="Apple Pay" title="Apple Pay" />,
  <StyledImg src={bizum_logo} alt="Bizum" title="Bizum" />,
  <StyledImg src={sepa_logo} alt="SEPA" title="SEPA" />,
  <StyledImg src={jcb_logo} alt="JCB" title="JCB" />,
  <StyledImg
    src={dinners_logo}
    alt="Dinners Club"
    title="Dinners Club"
    sx={{paddingRight: '20px'}}
  />,
  <StyledImg
    src={discover_logo}
    alt="Discover payment gateway"
    title="Discover payment gateway"
    sx={{paddingLeft: '20px'}}
  />
];

const MbWay: React.FC = () => {
  const {t} = useI18next();
  const faqs = [
    {
      header: t('What is MB WAY?'),
      text: t(
        "MB WAY is a mobile payment system that lets users make purchases, transfer money, and withdraw cash using their smartphones. It works through a smartphone app that is linked to a user's bank account or credit card."
      ),
      content: (
        <Trans>
          MB WAY is a <BlogLink slug="benefits-of-mobile-payments">mobile payment</BlogLink> system
          that lets users make purchases, transfer money, and withdraw cash using their smartphones.
          It works through a smartphone app that is linked to a user's bank account or credit card.
        </Trans>
      )
    },
    {
      header: t('How do I accept MB WAY payments in my business?'),
      text: t(
        'To accept MB WAY payments in your business, you’ll need to choose a payment service provider (PSP) that supports MB WAY (like MONEI).'
      ),
      content: (
        <Trans>
          To accept MB WAY payments in your business, you’ll need to choose a{' '}
          <BlogLink slug="what-is-a-psp">payment service provider (PSP)</BlogLink> that supports MB
          WAY (<AnchorLink href="https://dashboard.monei.com/">like MONEI</AnchorLink>).
        </Trans>
      )
    },
    {
      header: t('How much does it cost to use MB WAY as a merchant?'),
      text: t(
        'There are no extra costs to configure MB WAY in your MONEI account. Transaction fees are based on the payment method the customer selects while paying through their MB WAY wallet. For example, paying with their bank card might have a different transaction fee than paying with a credit card like Visa or Mastercard.'
      ),
      content: (
        <Trans>
          There are no extra costs to configure MB WAY in your MONEI account.{' '}
          <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink> are based on the{' '}
          <SupportLink slug="/categories/360003378957">payment method</SupportLink> the customer
          selects while paying through their MB WAY wallet. For example, paying with their bank card
          might have a different transaction fee than paying with a credit card like Visa or
          Mastercard.
        </Trans>
      )
    },
    {
      header: t('Is MB WAY secure for merchants?'),
      text: t(
        'Yes, MB WAY uses secure encryption methods to protect transactions, making it a secure payment method. And with MONEI as your payments partner, you don’t have to worry about PSD2 and PCI compliance, we’ve got you covered.'
      ),
      content: (
        <Trans>
          Yes, MB WAY uses secure encryption methods to protect transactions, making it a secure
          payment method. And with MONEI as your payments partner, you don’t have to worry about{' '}
          <BlogLink slug="psd2">PSD2</BlogLink> and{' '}
          <BlogLink slug="understanding-pci-compliance">PCI</BlogLink> compliance, we’ve got you
          covered.
        </Trans>
      )
    },
    {
      header: t('What should I do if a customer has a problem with their MB WAY payment?'),
      text: t(
        "The customer should contact their bank or MB WAY's customer support. As a merchant, you can assist them by providing any necessary transaction details. Simply go to the Payments tab in your MONEI Dashboard to look up transactions."
      ),
      content: (
        <Trans>
          The customer should contact their bank or MB WAY's customer support. As a merchant, you
          can assist them by providing any necessary transaction details. Simply go to the{' '}
          <AnchorLink href="https://dashboard.monei.com/payments/">
            Payments tab in your MONEI Dashboard
          </AnchorLink>{' '}
          to look up transactions.
        </Trans>
      )
    },
    {
      header: t('Do I need a special device to accept MB WAY payments?'),
      text: t(
        'No. You can integrate MB WAY into your e-commerce website with MONEI’s payment gateway. And with MONEI Pay (physical payments), all you need is your smartphone to accept contactless in-person payments.'
      ),
      content: (
        <Trans>
          No. You can{' '}
          <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
            integrate MB WAY into your e-commerce website
          </PrismicPage>{' '}
          with MONEI’s <InternalPageLink slug="payment-gateway">payment gateway</InternalPageLink>.
          And with MONEI Pay (physical payments), all you need is your smartphone to accept{' '}
          <BlogLink slug="contactless">contactless</BlogLink> in-person payments.
        </Trans>
      )
    },
    {
      header: t('Can I accept MB WAY payments online?'),
      text: t('Yes, MB WAY can be used for online payments as well as in-store purchases.'),
      content: (
        <Trans>Yes, MB WAY can be used for online payments as well as in-store purchases.</Trans>
      )
    },
    {
      header: t('What happens if an MB WAY payment fails?'),
      text: t(
        'If an MB WAY payment fails, the transaction will not be completed. The customer will need to try the payment again or use a different payment method. If the customer experiences frequent issues, they should contact their bank or card issuer. If your business experiences frequent failed MB WAY payments, contact support, and we’ll look into it. '
      ),
      content: (
        <Trans>
          If an MB WAY payment fails, the transaction will not be completed. The customer will need
          to try the payment again or use a different payment method. If the customer experiences
          frequent issues, they should contact their bank or card issuer. If your business
          experiences frequent failed MB WAY payments,{' '}
          <SupportLink slug="/requests/new">contact support</SupportLink>, and we’ll look into it.
        </Trans>
      )
    },
    {
      header: t('Are there transaction limits with MB WAY?'),
      text: t(
        'The minimum MB WAY transaction amount is €1.00 and the maximum amount is €5,000.00. Incomplete transactions timeout after four minutes.'
      ),
      content: (
        <Trans>
          The minimum MB WAY transaction amount is €1.00 and the maximum amount is €5,000.00.
          Incomplete transactions timeout after four minutes.
        </Trans>
      )
    },
    {
      header: t('How long does it take for an MB WAY payment to process?'),
      text: t(
        'MB WAY payments typically process immediately. Settlement times into your business bank account can vary depending on your MONEI account status. Once you’re a trusted and verified merchant, you’ll be eligible for 1-day payment settlements.'
      ),
      content: (
        <Trans>
          MB WAY payments typically process immediately.{' '}
          <SupportLink slug="/articles/360017952658">Settlement times</SupportLink> into your
          business bank account can vary depending on your MONEI account status. Once you’re a
          trusted and verified merchant, you’ll be eligible for 1-day payment settlements.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        title={t('Accept MB WAY Payments')}
        description={t(
          "Reach more Portuguese customers and increase sales by accepting MB WAY payments, Portugal's favorite mobile payment method. Get started ››"
        )}
        schema={schemaFaqs}
      />
      <IndexBackground
        sx={{
          left: {xl: '50%'},
          height: {xl: '1000px'},
          width: {xl: '2000px'},
          transform: {
            all: 'rotate(-58deg) matrix(1, 0, 0, 1, 260, -90)',
            xl: 'rotate(-58deg) matrix(1, 0, 0, 1, 140, -120)',
            lg: 'rotate(-58deg) matrix(1, 0, 0, 1, 160, -120)'
          }
        }}>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Accept MB WAY — Portugal’s favorite mobile payment method</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Portugal’s leading digital wallet, MB WAY, has over 3.5 million active users who
                make 9 million purchases each month. This popular mobile payment method lets
                customers store up to eight bank cards and pay online with their mobile phone number
                and MB WAY PIN.
              </Trans>
              <Trans parent="p">
                Sign up for MONEI today to accept MB WAY (and more payment methods) in your
                business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={mbWay_monei} alt="MONEI multibanco" title="MONEI multibanco" />
          </Section>
        </Content>
      </IndexBackground>

      <Content>
        <Section centered sx={{textAlign: 'center'}} column>
          <SectionHeader underline>
            <Trans>Why accept MB WAY wallet?</Trans>
          </SectionHeader>
          <CardsContainer style={{paddingBottom: '20px', marginTop: '50px'}}>
            <Card>
              <CardImage
                style={{transform: 'translateY(-30%)', top: 0, width: 110}}
                src={trendgraph}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  <Bold>94% of MB WAY users</Bold> always pay with MB WAY
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-50%)', top: 0, width: 105}}
                src={person}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  Almost <Bold>50% of Portuguese consumers</Bold> seek out merchants that accept MB
                  WAY
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{
                  transform: 'translateY(-50%) translateX(130%)',
                  top: 0,
                  width: 115,
                  left: 0
                }}
                src={heartbubble}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  <Bold>More than 50% of young adults</Bold> use MB WAY for online purchases
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, width: 75}}
                src={shield}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  Offers the same level of <Bold>payment security</Bold> as credit cards
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, width: 125}}
                src={mobile_payment}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  <Bold>Innovative and frictionless</Bold> payment flow
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage
                style={{transform: 'translateY(-45%)', top: 0, width: 75}}
                src={building2}
                alt="Subscriptions feature"
                title="Subscriptions feature"
              />
              <CardWrapper>
                <Trans parent="p">
                  Supported by 28 banks, providing <Bold>95% market coverage</Bold>
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section centered sx={{paddingTop: {md: '150px'}}}>
            <SectionImage
              src={modular}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              sx={{display: {md: 'none'}}}
            />
            <div>
              <SectionHeader>
                <Trans>How does MB WAY work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                With MB WAY, Portuguese consumers can complete transactions from their mobile
                devices.
              </Trans>
              <List>
                <Trans parent="li">
                  Customer chooses MB WAY at checkout and enters their mobile number.
                </Trans>
                <Trans parent="li">
                  Customer receives a payment request notification on their mobile device.
                </Trans>
                <Trans parent="li">
                  Customer selects their payment card and accepts the payment.
                </Trans>
                <Trans parent="li">Customer enters their PIN to authorize the payment.</Trans>
                <Trans parent="li">
                  "Payment authorized" message appears on their mobile device.
                </Trans>
                <Trans parent="li">The payment is confirmed on your website.</Trans>
              </List>
              <p>
                <Trans>
                  Accepting <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>{' '}
                  via MB WAY wallet means you’ll reach more international customers. But
                  diversifying your payment stack shouldn’t end there. Staying competitive requires
                  accepting{' '}
                  <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink>{' '}
                  including{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                  <BlogLink slug="alternative-payment-methods">
                    alternative payment methods
                  </BlogLink>{' '}
                  like{' '}
                </Trans>
                <PaymentMethodsLinkList exclude={['mb-way', 'credit-cards']} endTag={t('and')} />.
              </p>
              <Bold>
                <Trans parent="p">
                  Integrate with MONEI’s payments platform to manage it all from a single platform.
                </Trans>
              </Bold>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section centered sx={{textAlign: 'center'}} column>
          <SectionHeader underline>
            <Trans>Accept MB WAY payments with MONEI</Trans>
          </SectionHeader>
          <Bold>
            <Trans>Accept MB WAY online</Trans>
          </Bold>
          <Trans parent="p">
            Use a simple plugin to add MB WAY to e-commerce platforms like{' '}
            <InternalPageLink slug="shopify-payment-gateway">Shopify</InternalPageLink>,{' '}
            <InternalPageLink slug="prestashop-payment-gateway">PrestaShop</InternalPageLink>, and{' '}
            <InternalPageLink slug="magento-payment-gateway">
              Adobe Commerce (Magento 2)
            </InternalPageLink>
            , or integrate with your custom-built website using our{' '}
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>.
          </Trans>
          <br />
          <Bold>
            <Trans>Accept MB WAY in person</Trans>
          </Bold>
          <Trans parent="p">
            Do many Portuguese tourists visit your business in Spain? Download the{' '}
            <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
            <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
            <IosDownloadLink>iOS</IosDownloadLink>) to take MB WAY wallet payments from your phone
            or tablet. View online and physical transaction history from anywhere.
          </Trans>
          <SectionActions>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Content>
        <HomePartners />
        <Section centered sx={{textAlign: 'center'}} column>
          <SectionHeader underline>
            <Trans>More payment methods you may be interested in</Trans>
          </SectionHeader>
          <Slider
            items={paymentMethods}
            options={{
              perView: {all: 5, md: 4, sm: 3, xs: 2},
              autoplay: true,
              loop: true,
              spacing: {all: 40, md: 20, sm: 10}
            }}
            sx={{overflow: 'unset', display: {all: 'none', md: 'inherit'}}}
            slideHeight={150}
          />
          <PaymentMethods sx={{display: {md: 'none'}}} />
          <SectionActions>
            <ButtonLink href="/payment-methods" variant="dark">
              <Trans>Vew all payment methods</Trans>
            </ButtonLink>
          </SectionActions>
        </Section>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>MB WAY FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default MbWay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "mb-way"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
