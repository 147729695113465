import {AnchorLink} from 'components/AnchorLink';
import {Link} from 'gatsby-plugin-react-i18next';
import magento_logo from 'images/magento_logo.svg';
import octobercms_logo from 'images/octobercms_logo.svg';
import prestashop_logo from 'images/prestashop_logo.svg';
import salesforce_logo from 'images/salesforce_logo.svg';
import shopify_logo from 'images/shopify_payment_gateway_logo.svg';
import spreedly_logo from 'images/spreedly_logo.svg';
import commercetools_logo from 'images/commercetools_logo.svg';
import wix_logo from 'images/wix_black_logo.svg';
import woocommerce_logo from 'images/woocommerce_logo.svg';
import React from 'react';
import styled from 'styled-components';

export const PartnersContainer = styled.div`
  margin: 2.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px 82px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 28px;
  }
`;

export const StyledPartnerLogo = styled.div`
  opacity: 0.7;
  transition: 250ms opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 165px;
  min-width: 115px;

  :hover {
    opacity: 1;
  }
  img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

export const PartnerLogo: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <StyledPartnerLogo style={{width: `${props.width}px`, height: `${props.height}px`}}>
    <img title={props.title} alt={props.alt} src={props.src} />
  </StyledPartnerLogo>
);

export const HomePartners = ({...props}) => (
  <PartnersContainer {...props}>
    <Link to="/shopify-payment-gateway/">
      <PartnerLogo
        src={shopify_logo}
        alt="MONEI Payment Gateway"
        title="MONEI Payment Gateway"
        height={46}
      />
    </Link>
    <Link to="/woocommerce-payment-gateway/">
      <PartnerLogo
        src={woocommerce_logo}
        alt="WooCommerce payment gateway"
        title="WooCommerce payment gateway"
        height={38}
      />
    </Link>
    <AnchorLink href="https://docs.monei.com/docs/e-commerce/salesforce/" target="_blank">
      <PartnerLogo
        src={salesforce_logo}
        alt="Salesforce payment gateway"
        title="Salesforce payment gateway"
        height={80}
      />
    </AnchorLink>
    <Link to="/wix-payment-gateway/">
      <PartnerLogo
        src={wix_logo}
        alt="WIX payment gateway"
        title="WIX payment gateway"
        height={34}
      />
    </Link>
    <Link to="/magento-payment-gateway/">
    <PartnerLogo
      src={magento_logo}
      alt="Magneto payment gateway"
      title="Magneto payment gateway"
      height={50}
    />
    </Link>
    <PartnerLogo
      src={commercetools_logo}
      alt="Commercetools payment gateway"
      title="Commercetools payment gateway"
      height={40}
    />
    <Link to="/prestashop-payment-gateway/">
      <PartnerLogo
        src={prestashop_logo}
        alt="Prestashop payment gateway"
        title="Prestashop payment gateway"
        height={34}
      />
    </Link>
    <PartnerLogo
      src={octobercms_logo}
      alt="OctoberCMS payment gateway"
      title="OctoberCMS payment gateway"
      height={26}
    />
    <AnchorLink href="https://docs.spreedly.com/payment-gateways/monei/" target="_blank">
      <PartnerLogo
        src={spreedly_logo}
        alt="Spreedly payment gateway"
        title="Spreedly payment gateway"
        height={44}
      />
    </AnchorLink>
  </PartnersContainer>
);
